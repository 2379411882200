import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">


<path d="M0 2500 l0 -2500 2500 0 2500 0 0 2500 0 2500 -2500 0 -2500 0 0
-2500z m680 613 c0 5 5 5 10 2 6 -4 8 -11 4 -16 -7 -12 -34 8 -34 25 0 6 5 4
10 -4 5 -8 10 -11 10 -7z m83 -8 c-3 -9 -8 -14 -10 -11 -3 3 -2 9 2 15 9 16
15 13 8 -4z m-131 -19 c7 5 8 2 3 -6 -6 -10 -4 -12 10 -6 16 6 16 4 4 -10 -9
-11 -15 -12 -17 -5 -3 9 -8 9 -17 1 -10 -9 -13 -7 -10 7 2 10 4 23 5 28 1 6 4
4 7 -3 2 -6 10 -9 15 -6z m52 -20 c-3 -8 -13 -17 -22 -21 -13 -5 -13 -2 3 15
21 24 26 25 19 6z m80 -1 c-4 -8 -8 -15 -10 -15 -2 0 -4 7 -4 15 0 8 4 15 10
15 5 0 7 -7 4 -15z m-44 -5 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10
10 10 6 0 10 -4 10 -10z m116 -37 c-10 -10 -19 5 -10 18 6 11 8 11 12 0 2 -7
1 -15 -2 -18z m-248 9 c13 12 14 10 3 -9 -6 -13 -15 -23 -19 -23 -4 0 -8 10
-9 23 0 12 1 16 4 9 4 -10 8 -10 21 0z m192 -7 c0 -8 -4 -15 -10 -15 -5 0 -10
7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m-156 -17 c-5 -7 -8 -20 -6 -28 3
-19 -15 -47 -24 -38 -3 4 -3 15 1 25 5 10 7 22 6 27 -2 10 20 38 26 32 3 -3 1
-11 -3 -18z m40 -2 c-6 -16 -24 -23 -24 -8 0 10 22 33 27 28 2 -2 1 -11 -3
-20z m48 2 c-7 -7 -12 -8 -12 -2 0 14 12 26 19 19 2 -3 -1 -11 -7 -17z m168
-13 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z
m-341 -16 c-13 -24 -31 -35 -22 -13 5 13 3 15 -8 9 -11 -7 -12 -6 -2 6 18 23
45 21 32 -2z m263 -11 c-15 -15 -26 -4 -18 18 5 13 9 15 18 6 9 -9 9 -15 0
-24z m-237 -8 c-10 -11 -20 -18 -23 -15 -3 3 3 14 13 25 10 11 20 18 23 15 3
-3 -3 -14 -13 -25z m175 16 c0 -16 -18 -31 -27 -22 -8 8 5 36 17 36 5 0 10 -6
10 -14z m-70 -16 c-9 -17 -30 -28 -30 -16 0 16 15 36 26 36 12 0 12 -4 4 -20z
m260 -5 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10
-15z m-440 -26 c-12 -22 -16 -23 -26 -7 -4 7 -3 8 4 4 11 -7 17 6 13 27 -1 5
3 6 9 2 7 -4 7 -12 0 -26z m358 4 c-4 -22 -22 -20 -26 1 -2 10 3 16 13 16 10
0 15 -7 13 -17z m-328 -12 c-10 -21 -30 -37 -30 -26 0 14 21 45 31 45 6 0 6
-7 -1 -19z m40 -20 c-10 -20 -30 -29 -30 -13 0 4 8 18 17 32 19 27 30 12 13
-19z m118 -12 c-18 -12 -23 17 -6 38 12 15 13 14 16 -7 2 -12 -2 -27 -10 -31z
m92 36 c0 -15 -26 -32 -36 -23 -2 3 -2 13 2 22 8 20 34 21 34 1z m-140 -1 c0
-13 -21 -44 -30 -44 -11 0 -14 33 -3 43 8 8 33 9 33 1z m360 -15 c0 -5 -4 -9
-10 -9 -5 0 -10 7 -10 16 0 8 5 12 10 9 6 -3 10 -10 10 -16z m-535 -16 c-17
-41 -27 -90 -26 -125 1 -20 -5 -42 -13 -50 -19 -20 -29 -55 -16 -63 6 -4 10 4
10 19 0 14 6 29 13 33 7 4 18 21 25 36 15 35 36 25 28 -13 -3 -19 -1 -30 9
-34 21 -8 19 -52 -3 -68 -10 -7 -22 -25 -26 -40 -3 -16 -11 -28 -16 -28 -6 0
-10 -11 -10 -25 0 -16 6 -25 16 -25 8 0 12 5 9 10 -8 13 3 50 15 50 16 0 31
-40 25 -61 -4 -12 -13 -18 -20 -15 -10 4 -12 -3 -8 -30 3 -19 1 -34 -4 -34
-16 0 -33 23 -33 47 0 13 -4 23 -10 23 -10 0 -15 -35 -11 -80 1 -14 0 -22 -4
-18 -4 4 -9 28 -11 54 -3 26 -9 66 -14 87 -17 77 -6 147 37 230 2 4 2 7 -2 7
-3 0 -10 -10 -16 -22 -8 -20 -9 -19 -3 7 3 16 10 33 16 36 6 4 7 12 4 18 -4 6
-2 11 3 11 6 0 11 7 11 15 0 16 21 65 28 65 2 0 1 -8 -3 -17z m445 -3 c0 -11
-7 -20 -15 -20 -8 0 -15 9 -15 20 0 11 7 20 15 20 8 0 15 -9 15 -20z m1558
-306 l-3 -327 -38 -1 -37 -1 0 328 0 327 40 0 40 0 -2 -326z m206 310 c22 -21
20 -50 -5 -75 -22 -22 -55 -19 -76 9 -42 52 34 114 81 66z m326 -19 c0 -34 -1
-35 -38 -35 -45 0 -62 -25 -62 -90 l0 -40 55 0 55 0 0 -35 0 -35 -55 0 -55 0
0 -190 0 -190 -40 0 -40 0 0 190 0 190 -30 0 c-23 0 -30 5 -30 19 0 11 -3 26
-6 35 -5 13 1 16 29 16 l34 0 5 65 c9 103 38 133 131 134 l47 1 0 -35z m710
-290 l0 -325 -40 0 -40 0 0 325 0 325 40 0 40 0 0 -325z m-3226 283 c-4 -13
-7 -34 -5 -48 1 -14 -5 -34 -14 -45 -15 -19 -16 -18 -10 15 3 19 6 44 6 54 0
23 18 60 26 53 3 -3 1 -16 -3 -29z m1264 -242 c-3 -259 -4 -276 -24 -302 -37
-51 -70 -68 -138 -72 -105 -7 -177 44 -192 136 l-7 42 41 0 c39 0 41 -2 48
-35 13 -72 103 -100 161 -51 l28 24 3 266 3 266 40 0 40 0 -3 -274z m-1228
224 c-13 -30 -30 -41 -30 -17 0 16 30 60 37 53 3 -3 0 -19 -7 -36z m300 20 c0
-13 -7 -20 -20 -20 -19 0 -27 20 -13 33 13 14 33 6 33 -13z m-240 -15 c0 -23
-24 -45 -36 -33 -7 7 17 58 28 58 4 0 8 -11 8 -25z m70 0 c0 -24 -24 -44 -37
-31 -9 8 14 56 27 56 5 0 10 -11 10 -25z m80 6 c0 -24 -18 -37 -31 -24 -13 13
-1 43 17 43 8 0 14 -9 14 -19z m340 9 c0 -5 -4 -10 -9 -10 -6 0 -13 5 -16 10
-3 6 1 10 9 10 9 0 16 -4 16 -10z m-102 -27 c-4 -22 -22 -20 -26 1 -2 10 3 16
13 16 10 0 15 -7 13 -17z m-98 -24 c0 -20 -24 -26 -35 -9 -8 13 14 42 26 34 5
-3 9 -14 9 -25z m270 11 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10
10 6 0 10 -4 10 -10z m-609 -42 c-12 -33 -29 -44 -37 -24 -8 21 25 71 37 59 6
-6 6 -19 0 -35z m245 27 c4 -8 0 -22 -7 -31 -10 -13 -14 -13 -26 -1 -16 16 -7
47 13 47 8 0 17 -7 20 -15z m-176 -19 c0 -25 -22 -51 -33 -39 -11 10 -8 38 5
51 18 18 28 14 28 -12z m87 8 c7 -19 -20 -49 -36 -40 -12 8 -15 39 -4 49 12
12 33 7 40 -9z m353 -4 c0 -11 -7 -20 -15 -20 -8 0 -15 9 -15 20 0 11 7 20 15
20 8 0 15 -9 15 -20z m-106 -16 c7 -19 -1 -34 -19 -34 -8 0 -16 4 -19 9 -9 14
4 41 19 41 7 0 16 -7 19 -16z m-109 -34 c0 -28 -29 -40 -38 -15 -9 22 3 46 22
43 10 -2 16 -13 16 -28z m-100 -16 c0 -22 -5 -29 -20 -29 -23 0 -32 30 -14 51
18 22 34 11 34 -22z m386 21 c-1 -15 -24 -12 -29 3 -3 9 2 13 12 10 10 -1 17
-7 17 -13z m-555 -9 c7 -19 -13 -56 -31 -56 -15 0 -20 26 -9 54 8 20 33 21 40
2z m82 -12 c3 -23 -21 -49 -38 -39 -11 7 -14 48 -3 59 13 13 38 1 41 -20z
m370 -7 c4 -20 -25 -34 -40 -19 -15 15 -1 44 19 40 10 -2 19 -11 21 -21z
m-119 -11 c15 -18 3 -46 -19 -46 -10 0 -21 7 -24 16 -6 16 9 44 24 44 4 0 13
-6 19 -14z m301 -1 c0 -8 -4 -15 -9 -15 -13 0 -22 16 -14 24 11 11 23 6 23 -9z
m2242 -31 c67 -47 93 -102 93 -199 0 -98 -26 -153 -95 -200 -41 -27 -52 -30
-126 -30 -71 0 -85 3 -122 27 -22 15 -53 49 -69 75 -24 42 -28 58 -28 128 0
69 4 86 28 127 47 81 102 109 209 105 56 -2 74 -8 110 -33z m688 15 c21 -12
48 -40 61 -63 22 -38 24 -54 27 -208 l4 -168 -41 0 c-39 0 -41 1 -41 32 l0 32
-35 -34 c-30 -29 -43 -34 -94 -38 -73 -5 -123 10 -155 49 -54 64 -42 151 26
197 36 24 52 27 144 30 l104 4 0 33 c0 92 -164 119 -202 33 -7 -15 -20 -28
-28 -29 -8 0 -25 -2 -38 -3 -30 -3 -35 6 -21 41 37 98 191 147 289 92z m-3335
-19 c0 -16 -6 -26 -19 -28 -25 -5 -39 12 -31 38 10 31 50 24 50 -10z m1164
-125 c1 -86 6 -166 11 -179 20 -53 102 -74 160 -40 55 33 65 65 68 227 l4 147
39 0 39 0 0 -225 0 -225 -40 0 c-38 0 -40 1 -40 32 l0 32 -27 -26 c-36 -35
-73 -48 -131 -48 -64 0 -112 27 -141 81 -23 40 -25 56 -29 211 l-5 168 46 0
45 0 1 -155z m731 -70 l0 -225 -45 0 -45 0 0 225 0 225 45 0 45 0 0 -225z
m-2092 184 c3 -32 -12 -53 -32 -46 -19 7 -21 48 -4 65 17 17 33 9 36 -19z m96
15 c8 -22 -12 -56 -32 -52 -18 3 -27 30 -18 54 7 19 42 18 50 -2z m393 0 c8
-20 -8 -36 -26 -25 -15 10 -8 41 9 41 6 0 13 -7 17 -16z m-117 -19 c16 -19 6
-45 -19 -45 -23 0 -35 28 -20 46 15 18 24 18 39 -1z m310 5 c0 -5 -4 -10 -10
-10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-425 -45 c0 -23 -5
-30 -19 -30 -28 0 -43 29 -26 50 21 25 45 14 45 -20z m310 5 c0 -22 -29 -18
-33 3 -3 14 1 18 15 15 10 -2 18 -10 18 -18z m-525 -5 c18 -21 4 -65 -20 -65
-24 0 -38 44 -20 65 7 8 16 15 20 15 4 0 13 -7 20 -15z m108 -18 c4 -33 -22
-47 -45 -24 -22 21 -8 59 20 55 16 -2 23 -11 25 -31z m-195 1 c7 -26 -10 -61
-26 -55 -14 4 -21 41 -13 63 8 21 33 17 39 -8z m497 -8 c0 -24 -26 -38 -42
-22 -16 16 -2 42 22 42 13 0 20 -7 20 -20z m-125 -30 c0 -18 -6 -26 -21 -28
-23 -3 -37 18 -27 44 10 25 48 13 48 -16z m315 15 c0 -8 -4 -15 -9 -15 -13 0
-22 16 -14 24 11 11 23 6 23 -9z m-534 -41 c7 -28 -2 -44 -25 -44 -37 0 -52
37 -26 64 20 19 44 10 51 -20z m109 15 c13 -41 -44 -63 -59 -23 -9 23 5 44 29
44 15 0 26 -8 30 -21z m-227 -1 c7 -7 12 -25 12 -40 0 -21 -5 -28 -20 -28 -16
0 -19 -5 -14 -29 4 -17 1 -32 -5 -36 -18 -11 -41 15 -41 46 0 22 5 29 19 29
14 0 18 5 14 20 -6 22 2 50 15 50 4 0 13 -5 20 -12z m539 -4 c8 -22 -18 -37
-36 -20 -7 8 -10 19 -7 25 10 16 36 13 43 -5z m-127 -19 c16 -20 6 -45 -19
-45 -25 0 -34 14 -26 40 8 24 28 26 45 5z m320 5 c0 -5 -4 -10 -10 -10 -5 0
-10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-440 -25 c6 -8 10 -22 8 -32
-5 -25 -51 -25 -56 0 -8 42 23 63 48 32z m-102 -37 c5 -26 -26 -50 -49 -38
-22 12 -27 65 -6 73 16 6 51 -16 55 -35z m422 22 c0 -11 -7 -20 -15 -20 -8 0
-15 9 -15 20 0 11 7 20 15 20 8 0 15 -9 15 -20z m-542 -34 c4 -34 -8 -49 -31
-40 -19 7 -33 47 -22 64 4 6 17 10 28 8 16 -2 23 -11 25 -32z m426 15 c10 -16
-5 -41 -24 -41 -19 0 -34 25 -24 41 3 5 14 9 24 9 10 0 21 -4 24 -9z m-125
-25 c16 -20 3 -46 -24 -46 -25 0 -38 21 -29 45 8 19 38 19 53 1z m319 2 c-6
-18 -28 -21 -28 -4 0 9 7 16 16 16 9 0 14 -5 12 -12z m-810 -94 c-1 -75 -18
-55 -24 27 -5 58 -4 62 10 46 9 -12 14 -39 14 -73z m373 70 c9 -11 10 -20 2
-32 -13 -22 -43 -22 -56 -1 -24 38 25 68 54 33z m-311 -13 c14 -27 13 -61 -3
-61 -9 0 -12 -10 -10 -32 l4 -33 -20 24 c-22 24 -28 61 -11 61 5 0 7 7 4 15
-7 18 2 45 15 45 6 0 15 -9 21 -19z m82 2 c25 -23 24 -63 -2 -63 -23 0 -46 40
-37 64 8 20 16 20 39 -1z m116 -17 c5 -37 -10 -51 -37 -37 -14 8 -21 21 -21
42 0 27 3 30 28 27 21 -2 28 -9 30 -32z m432 14 c0 -13 -7 -20 -20 -20 -13 0
-20 7 -20 20 0 13 7 20 20 20 13 0 20 -7 20 -20z m-136 -6 c21 -8 21 -43 0
-51 -8 -3 -23 1 -31 10 -13 13 -14 19 -3 31 14 18 13 18 34 10z m-116 -26 c15
-15 16 -45 2 -53 -19 -12 -50 6 -50 29 0 36 24 48 48 24z m322 -13 c0 -8 -7
-15 -15 -15 -8 0 -15 7 -15 15 0 8 7 15 15 15 8 0 15 -7 15 -15z m-432 -7 c7
-7 12 -22 12 -35 0 -17 -6 -23 -23 -23 -29 0 -47 18 -47 47 0 17 6 23 23 23
13 0 28 -5 35 -12z m-208 -17 c15 -29 12 -51 -6 -51 -15 0 -15 -2 -2 -22 23
-35 39 -34 32 1 -5 25 -3 31 10 31 12 0 16 7 14 27 -3 31 23 43 47 23 23 -19
19 -60 -5 -60 -17 0 -18 -3 -8 -25 6 -14 19 -25 28 -25 25 0 56 -38 43 -51
-15 -15 -51 -2 -58 22 -10 30 -37 25 -29 -6 5 -19 2 -25 -11 -25 -13 0 -15 -3
-8 -12 6 -7 14 -31 17 -52 6 -28 10 -36 16 -26 5 9 4 20 -3 29 -9 10 -9 15 -1
18 6 2 9 15 6 28 -5 29 5 31 36 9 24 -17 29 -40 10 -47 -10 -3 -10 -7 1 -20
10 -13 10 -17 1 -17 -8 0 -9 -4 -3 -12 6 -7 13 -21 16 -31 5 -14 3 -16 -12 -8
-10 6 -21 17 -25 26 -3 8 -10 15 -16 15 -6 0 -8 -5 -4 -11 13 -21 -45 22 -59
44 -20 31 -30 77 -17 77 7 0 7 6 0 20 -6 11 -15 20 -19 20 -10 0 -7 -46 4 -73
13 -32 -2 -29 -23 5 -23 39 -38 93 -22 83 5 -3 10 -2 10 4 0 5 -4 13 -10 16
-15 9 -12 35 3 35 9 0 12 10 9 30 -3 23 0 30 12 30 8 0 20 -9 26 -19z m520 4
c15 -18 5 -35 -21 -35 -12 0 -19 7 -19 18 0 33 20 42 40 17z m188 -17 c-6 -18
-28 -21 -28 -4 0 9 7 16 16 16 9 0 14 -5 12 -12z m-303 -23 c0 -31 -49 -28
-53 3 -3 20 0 23 25 20 20 -2 28 -8 28 -23z m-131 2 c23 -17 17 -52 -8 -52
-23 0 -41 27 -33 49 8 19 17 20 41 3z m-344 -57 c0 -10 3 -27 6 -37 3 -10 -3
-5 -14 11 -12 17 -19 33 -16 37 2 4 0 10 -6 14 -5 3 -10 14 -10 23 1 14 4 13
20 -7 11 -13 20 -31 20 -41z m668 43 c5 -17 -26 -29 -40 -15 -6 6 -7 15 -3 22
9 14 37 9 43 -7z m-422 -17 c11 -46 -13 -59 -46 -26 -24 24 -16 50 16 50 17 0
25 -7 30 -24z m304 -17 c0 -14 -7 -19 -25 -19 -18 0 -25 5 -25 19 0 11 6 21
13 24 20 7 37 -4 37 -24z m-112 -23 c-4 -28 -51 -30 -56 -2 -5 23 13 35 40 28
13 -3 18 -12 16 -26z m292 19 c0 -8 -7 -15 -15 -15 -8 0 -15 7 -15 15 0 8 7
15 15 15 8 0 15 -7 15 -15z m-406 -27 c8 -13 7 -21 -1 -29 -23 -23 -74 15 -57
42 9 15 45 6 58 -13z m294 -5 c-2 -10 -10 -18 -18 -18 -8 0 -16 8 -18 18 -2
12 3 17 18 17 15 0 20 -5 18 -17z m162 -3 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4
10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-270 -19 c0 -20 -17 -31 -37 -24 -7 3 -13
13 -13 24 0 14 7 19 25 19 18 0 25 -5 25 -19z m-112 -28 c3 -17 -2 -23 -16
-23 -24 0 -46 25 -37 40 12 19 50 7 53 -17z m282 12 c0 -8 -7 -15 -15 -15 -8
0 -15 7 -15 15 0 8 7 15 15 15 8 0 15 -7 15 -15z m-392 -7 c18 -18 15 -38 -6
-38 -21 0 -42 18 -42 37 0 16 32 17 48 1z m290 -15 c5 -17 -26 -29 -40 -15 -6
6 -7 15 -3 22 9 14 37 9 43 -7z m152 -3 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10
3 6 8 10 11 10 2 0 4 -4 4 -10z m359 -62 c14 -37 29 -69 32 -73 4 -3 20 27 36
66 16 40 34 74 41 77 19 6 14 -17 -24 -115 -36 -91 -57 -114 -71 -75 -3 9 -19
47 -34 84 -30 69 -37 112 -18 106 7 -2 23 -34 38 -70z m-616 48 c7 -17 -1 -26
-24 -26 -21 0 -33 15 -24 30 9 15 42 12 48 -4z m1152 -1 c0 -5 -15 -11 -32
-13 l-33 -3 0 -85 c0 -68 -3 -84 -15 -84 -12 0 -15 16 -15 85 l0 85 -30 0
c-20 0 -30 5 -30 15 0 13 13 15 78 13 43 -2 77 -7 77 -13z m303 -61 c3 -71 16
-94 53 -94 35 0 49 28 49 95 0 63 14 91 31 64 14 -22 10 -107 -6 -139 -29 -56
-95 -68 -135 -25 -37 39 -43 184 -7 173 7 -3 13 -30 15 -74z m856 54 c13 -21
0 -36 -16 -20 -7 7 -22 12 -35 12 -40 0 -27 -33 22 -59 50 -26 60 -58 28 -93
-28 -31 -113 -18 -113 17 0 18 21 20 37 4 16 -16 57 -7 61 13 2 11 -10 24 -37
38 -54 27 -68 53 -46 86 13 20 23 25 52 22 20 -2 41 -10 47 -20z m334 -54 c3
-70 16 -94 51 -94 36 1 46 19 51 92 4 56 8 73 20 73 12 0 15 -15 15 -73 0 -68
-2 -76 -28 -98 -38 -32 -86 -32 -117 1 -37 39 -43 184 -7 173 7 -3 13 -30 15
-74z m559 47 c8 -18 9 -30 1 -44 -7 -13 -7 -22 1 -30 16 -16 13 -58 -6 -79
-12 -13 -31 -18 -75 -18 l-58 0 0 100 0 101 62 -3 c55 -3 64 -6 75 -27z
m-3311 5 c15 -12 17 -17 6 -28 -15 -15 -45 0 -50 26 -4 20 18 21 44 2z m-94
-13 c23 -20 23 -33 1 -33 -13 0 -14 -3 -5 -12 19 -19 14 -30 -10 -24 l-23 6
23 -11 c12 -7 22 -18 22 -25 0 -7 6 -14 13 -17 6 -2 9 -7 6 -11 -10 -10 -65
46 -72 72 -4 16 -1 22 10 22 14 0 14 2 0 18 -10 10 -17 22 -17 25 0 13 34 7
52 -10z m368 7 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15
-4 15 -10z m-100 -25 c0 -8 -9 -15 -20 -15 -11 0 -20 7 -20 15 0 8 9 15 20 15
11 0 20 -7 20 -15z m-100 -5 c11 -7 12 -12 3 -21 -15 -15 -43 -5 -43 16 0 17
18 19 40 5z m250 0 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6
0 10 -4 10 -10z m-349 -10 c10 -6 19 -15 19 -20 0 -14 -34 -12 -48 2 -26 26
-7 37 29 18z m259 -10 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10
8 0 15 -4 15 -10z m-92 -18 c2 -7 -6 -12 -17 -12 -21 0 -27 11 -14 24 9 9 26
2 31 -12z m142 -2 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4
-4 4 -10z m-320 -10 c20 -13 21 -45 1 -57 -13 -8 -17 -7 -17 8 0 11 5 19 11
19 5 0 2 5 -7 11 -18 10 -25 29 -10 29 4 0 14 -5 22 -10z m95 -1 c4 -6 5 -13
2 -16 -8 -7 -47 7 -47 18 0 13 37 11 45 -2z m145 -9 c0 -5 -7 -10 -15 -10 -8
0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z m-80 -22 c0 -6 -7 -7 -16 -4
-9 3 -18 6 -20 6 -3 0 -3 4 1 10 7 11 35 2 35 -12z m-80 1 c12 -9 13 -12 1
-16 -11 -5 -11 -7 0 -15 11 -7 9 -8 -5 -6 -11 2 -21 13 -22 26 -4 24 3 27 26
11z m210 1 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4
10 -10z m-73 -16 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-67
-19 c0 -8 -8 -15 -17 -14 -13 0 -14 2 -4 6 11 5 11 7 0 14 -11 7 -10 9 4 9 9
0 17 -7 17 -15z m60 -16 c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10 16 0 8 5 12 10 9
6 -3 10 -10 10 -16z"/>
<path d="M440 2681 c0 -11 -6 -26 -13 -33 -21 -21 -27 -66 -9 -73 10 -4 12 0
8 11 -9 25 3 56 20 50 10 -4 14 3 14 24 0 16 -4 32 -10 35 -5 3 -10 -3 -10
-14z"/>
<path d="M381 2634 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M392 2550 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M3222 2629 c-40 -26 -61 -65 -69 -129 -11 -94 57 -180 143 -180 115
0 180 142 119 260 -34 67 -128 90 -193 49z"/>
<path d="M3948 2463 c-45 -7 -75 -72 -50 -109 25 -38 114 -46 156 -13 32 25
56 69 56 104 0 24 -1 25 -67 24 -38 -1 -80 -4 -95 -6z"/>
<path d="M4072 2118 c2 -19 9 -23 38 -23 28 0 35 4 35 20 0 16 -8 21 -38 23
-35 3 -38 1 -35 -20z"/>
<path d="M4070 2031 c0 -28 3 -31 33 -31 41 0 60 12 55 36 -2 14 -13 20 -46
22 -41 3 -42 2 -42 -27z"/>
</g>
</svg>

    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}